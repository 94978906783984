/* eslint-disable react-hooks/exhaustive-deps */
import { TDDate, handleSortCinemas, toggleLanguage } from "@/helper";
import useContentGlobal from "@/hooks/useContentGlobal";
import { ConfigProvider, Select, Skeleton } from "antd";
import moment from "moment";
import Image from "next/image";
type DefaultItemType = {
  value: string,
  label: string,
  type: string,
  data: any
}
type NavigateItemType = {
  id: string,
  defaultValue: DefaultItemType,
  options: DefaultItemType[],
  content: {
    label: string,
    icon: any
  }
}
type TProps = {
  listMovie: TMovie[]
  listCinemas: TCinima[]
  listDate: TDDate[]
  date?: TDDate
  handleSelectChange: (e: string, value: any) => void
  handleSelectDate?: (e: TDDate) => void
  dataShowtimes?: any
  filter?: Record<string, any>
}
export const FilterBar: React.FC<TProps> = ({ listMovie, listCinemas, listDate, handleSelectChange, filter }) => {
  const { getValueByKey, status } = useContentGlobal()

  const handleGetName = (cinema: TCinima) => {
    const alias = toggleLanguage(cinema, 'alias')
    if (alias) return alias
    return toggleLanguage(cinema, 'name')
  }
  const isCurrentDate = (date: string) => {
    const someDate = moment(date)
    return someDate?.isSame(moment(), 'day')
  }

  const initialValue: NavigateItemType[] = [
    {
      id: 'date',
      defaultValue: {
        value: '0',
        label: getValueByKey('Select_Date') ?? 'Chọn ngày',
        type: 'date',
        data: {}
      },
      options: listDate?.map((item: TDDate) => ({
        value: item?.value,
        label: isCurrentDate(item?.date) ? `${getValueByKey("Today") || "Hôm nay"} ${item?.value}` : `${toggleLanguage(item, "label")} ${item?.value}`,
        type: 'date',
        data: item
      })),
      content: {
        label: getValueByKey("Date") ?? "Ngày",
        icon: <Image width={30} height={30} fetchPriority="high" src="/assets/images/icon-calendar-1.svg" alt="" className="showtimes-icon" />
      }
    },
    {
      id: 'idMovie',
      defaultValue: {
        value: '1',
        label: getValueByKey('Select_Movie') ?? 'Chọn phim',
        type: 'idMovie',
        data: {}
      },
      options: listMovie?.map((item: any) => ({
        value: item?.id,
        label: handleGetName(item),
        type: 'idMovie',
        data: item
      })),
      content: {
        label: getValueByKey("Movie") ?? "Phim",
        icon: <Image width={30} height={30} fetchPriority="high" src="/assets/images/icon-movie-1.svg" alt="" className="showtimes-icon" />
      }
    },
    {
      id: 'idTheater',
      defaultValue: {
        value: "2",
        label: getValueByKey("Select_Theater") ?? "Chọn rạp",
        type: "idTheater",
        data: {}
      },
      options: handleSortCinemas(listCinemas)?.map((item: any) => ({
        value: item?.id,
        label: handleGetName(item),
        type: 'idTheater',
        data: item
      })),
      content: {
        label: getValueByKey("Theater") ?? "Rạp",
        icon: <Image width={200} height={200} fetchPriority="high" src="/assets/images/icon-maps-1.svg" alt="" className="showtimes-icon" />
      }
    },
  ]

  const renderContent = () => {
    return initialValue.map((content, key) => {
      const isDate = content?.id == "date"
      const defaultValue = isDate ? content?.options[0] : content?.defaultValue
      const value = (isDate ? filter?.[content?.id]?.value : filter?.[content?.id]?.id) ?? defaultValue
      return status == "success" ? <div className={`showtime-filter-item ${content?.id == "idMovie" ? "--large" : ""}`} key={content?.id ?? key}>
        <div className="showtime-filter-heading">
          <span>{key + 1}. {content?.content?.label}</span>
          <span>{content?.content?.icon}</span>
        </div>
        <div className="select-location-second navigate-filter-item">
          <Select
            popupMatchSelectWidth={true}
            value={value}
            className="area-theater"
            onChange={(e, value) => handleSelectChange(e as any, value)}
            options={content?.options}
            showAction={["click", "focus"]}
          />
        </div>
      </div> : <Skeleton.Input key={content?.id ?? key} active size={"large"} block />
    })

  }

  return <ConfigProvider
    theme={{
      components: {
        Select: {
          optionSelectedBg: "#F3EA28"
        },
      }
    }}>
    <section className="showtime-filter">
      <div className="container">
        <div className="showtime-filter-items">
          {/* <div className="showtime-filter-item">
            <div className="showtime-filter-heading">
              <span>1. {getValueByKey("Date") ?? "Ngày"}</span>
              <span><Image width={30}  height={30} fetchPriority="high" src="/assets/images/icon-calendar-1.svg" alt="" className="showtimes-icon"/></span>
            </div>
            <div className="showtime-date">
              {listDate?.map((item: any, index: number) => {
                return <Fragment key={index}>
                  <div
                    key={index}
                    onClick={() => {
                      handleSelectDate(item as TDDate)
                      // router.push({
                      //   query: {
                      //     ...router?.query,
                      //     show_time: '',
                      //     date: ''
                      //   }
                      // }, undefined, { scroll: false })
                    }}
                    className={clsx(
                      date?.value == item?.value && "active",
                      "showtime-date-item",
                    )}
                  >
                    <div className={"box-time"}>
                      <p className="date">{item?.value}</p>
                      <p className="day">{toggleLanguage(item, "label")}</p>
                    </div>
                  </div>
                </Fragment>
              })}
            </div>
          </div> */}
          {renderContent()}
        </div>
      </div>
    </section>
  </ConfigProvider>
}