import { FilterBar, ListMovie } from "@/components/screen/showtimes"
import { TDDate, convertDate } from "@/helper"
import moment from "moment"
import { useMemo, useState } from "react"

type TProps = {
  res: {
    res: {
      listMovie: TMovie[]
      listCinemas: TCinima[]
      movie: TMovie[]
      dataShowtimes: any
    }
  }
}

export const ShowtimePage: React.FC<TProps> = ({ res }) => {
  const listDate: TDDate[] = res?.res?.dataShowtimes?.reduce((acc: string[], el: any) => {
    el?.schedule?.forEach((i: any) => {
      !acc?.includes(i?.date) && moment(i?.date, "DD/MM/YYYY").startOf('day').isSameOrAfter(moment().startOf('day')) && acc.push(i?.date)
      return acc
    })
    return acc
  }, [])?.map((el: string) => convertDate(el))?.sort((a: TDDate, b: TDDate) => moment(a?.date).diff(moment(b?.date)))
  const defaultDate = listDate?.[0] as TDDate ?? convertDate(moment()?.format("DD/MM/YYYY"))
  const [filter, setFilter] = useState({ date: defaultDate })

  const handleSelectChange = (_: string, value: any) => {
    setFilter(prev => ({ ...prev, [value.type]: value?.data }))
  }

  const commonProps = useMemo(() => {
    return {
      listMovie: res?.res?.listMovie,
      listCinemas: res?.res?.listCinemas,
      dataShowtimes: res?.res?.dataShowtimes,
    }
  }, [])

  return (
    <>
      <FilterBar {...commonProps} filter={filter} listDate={listDate?.length > 0 ? listDate : [defaultDate]} handleSelectChange={handleSelectChange} />
      <ListMovie {...commonProps} filter={filter} />
    </>)
}