/* eslint-disable @next/next/no-img-element */
import { TDDate, toggleLanguage } from "@/helper"

import { renderTextWarningOld } from "@/config/nameLimitAge"
import useContentGlobal from "@/hooks/useContentGlobal"
import { FloatButton } from "antd"
import Image from "next/image"
import Link from "next/link"
import { useCallback, useEffect, useMemo, useState } from "react"
import { MovieSection } from "../movie"
import { MovieItem } from "./MovieItem"
import moment from "moment"

type TProps = {
  listMovie: TMovie[]
  listCinemas: TCinima[]
  filter: {
    date?: TDDate
    idMovie?: TMovie
    idTheater?: TCinima
  }
  dataShowtimes: any
}
export const ListMovie: React.FC<TProps> = ({ listCinemas, listMovie, filter, dataShowtimes }) => {
  const [showtimes, setShowtimes] = useState<TMovie[]>([])
  const [isLoadMore, setLoadMore] = useState(false)
  const { getValueByKey } = useContentGlobal();
  // const {data: dataShowtime, isLoading} = useQuery({
  //   queryKey: ["showtimes"],
  //   queryFn:  async () => {
  //     const res = await fetch(`/api/showTime?id_Movie=0&id_Area=0&id_Server=0&date=0&id_MovieTheater=0`)
  //     .then((res) => res.json())
  //     return res
  //   }
  //   })

  const handleLoadFirstThreeMovies = useCallback(() => {
    const newMovies = filter?.idMovie ? listMovie?.filter(el => el?.id == filter?.idMovie?.id) : listMovie?.slice(0, 3)
    const movie = dataShowtimes?.filter((movie: any) => movie?.id == newMovies?.find((el: any) => el?.id == movie?.id)?.id)
    setShowtimes(movie)
    setLoadMore(false)
  }, [dataShowtimes, filter?.idMovie, listMovie])

  useEffect(() => {
    handleLoadFirstThreeMovies()
  }, [handleLoadFirstThreeMovies])

  const handleLoadTheRestMovies = () => {
    const movies = listMovie?.filter(el => !(showtimes?.find(m => m?.id == el?.id)))
    const newList = dataShowtimes?.filter((movie: any) => movie?.id == movies?.find((el: any) => el?.id == movie?.id)?.id)
    setShowtimes(prev => {
      if (!prev) return newList
      return [...prev, ...newList] as TMovie[]
    })
    setLoadMore(true)
  }

  const isExistMovies = useMemo(() => listMovie?.filter((el: TMovie) => !showtimes?.find((item: TMovie) => el.id == item.id)), [listMovie, showtimes])

  const scrollToTop = () => {
    window?.scrollTo({ top: 0, behavior: "smooth" })
  }

  // if(isLoading) return <p>Loading...</p>
  return <section className="movies-showtimes">
    <div className="container">
      {
        dataShowtimes?.every((movie: any) => movie?.id == null || movie?.image == null) || showtimes?.length < 1 ? <div className='mona-noti-custom'>
          <div className="container">
            <div className="box">
              <i className="fa-sharp fa-regular fa-film-slash"></i>
              <p> {getValueByKey("Showtime_Empty")}</p>
            </div>
          </div>
        </div> : (showtimes && showtimes?.length > 0 && showtimes.map((item: any) => {
          return <div key={item?.id} className="row movies-showtime">
            <div className="col sec-showtimes-left">
              <Link href={`/movie/${item?.id} ${'?date=' + moment(filter?.date?.date).format("DD/MM/YYYY")} ${filter?.idTheater?.id ? `&id=${filter?.idTheater?.area_id}&id_sv=${filter?.idTheater?.id_server}` : ""}`} className="inner">
                <Image objectFit="cover" width={200} height={200} sizes="(max-width: 768px) 100vw, 33vw" src={item?.image ?? ""} alt="" />
              </Link>
              <h3 className="movies-name">
                <Link href={""}>{toggleLanguage(item, "name")}</Link>
              </h3>
              <div className="movies-type">
                <ul>
                  <li>
                    <img src="/assets/images/icon-tag.svg" alt="" />
                    <span className="txt">{toggleLanguage(item, 'type_name')}</span>
                  </li>
                  <li>
                    <img src="/assets/images/icon-clock.svg" alt="" />
                    <span className="txt">{item?.time_m}</span>
                  </li>
                  <li>
                    <i className="fa-regular fa-earth-americas"></i>
                    <span className="txt">{toggleLanguage(item, 'country_name')}</span>
                  </li>
                  <li>
                    <img src="/assets/images/subtitle.svg" alt="" />
                    <span className="txt">{toggleLanguage(item, "language")}</span>
                  </li>
                  <li>
                    <i className="fa-regular fa-user-check"></i>
                    <span className="txt">{toggleLanguage(item, "limitage")}: {renderTextWarningOld(toggleLanguage(item, "limitage"))}</span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col sec-showtimes-right movie-item">
              {/* {handleSortCinemas(newCinemas).map(cinema => {
              return <Fragment key={cinema?.id}>
                <MovieItem movie={item} theater={cinema} filter={filter} />
              </Fragment>
            })} */}
              <MovieItem movie={item} theaters={listCinemas} filter={filter} />
            </div>
          </div>
        }))
      }
      {showtimes?.length > 0 && !(showtimes?.some(i => i?.id == null)) && <div className="btn-more" data-aos="fade-up">
        {isLoadMore ? <button className="btn btn--outline --wf --mw --m-mid" onClick={handleLoadFirstThreeMovies}>{getValueByKey("Collapse") ?? "Thu gọn"}</button> : <button className="btn btn--outline --wf --mw --m-mid" onClick={handleLoadTheRestMovies}>{getValueByKey("Views_All_Showtimes") || "Xem tất cả lịch chiếu"}</button>}
      </div>}
      <FloatButton.BackTop className="btn-scroll-to-top" visibilityHeight={600} onClick={scrollToTop}>Scroll to top</FloatButton.BackTop>
      {isExistMovies && isExistMovies?.length > 0 && <div className="the-rest-movie">
        <MovieSection movies={isExistMovies} noTitle isShowMoreUrl={"/movie/showing"} />
      </div>}
    </div>
  </section>
}