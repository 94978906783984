/* eslint-disable @next/next/no-img-element */
import { TDDate, toggleLanguage } from "@/helper"
import useContentGlobal from "@/hooks/useContentGlobal"
import moment from "moment"
import Link from "next/link"
import { Fragment } from "react"

type Props = {
  movie: any
  theaters: TCinima[]
  filter?: {
    date?: TDDate
    idMovie?: TMovie
    idTheater?: TCinima
  }
}
export const MovieItem = ({ movie, theaters, filter }: Props) => {
  const { getValueByKey } = useContentGlobal()
  const renderTheaterName = (theater: TCinima) => {
    const fullName = toggleLanguage(theater, "name")?.split(" ");
    if (!fullName || fullName.length < 1) return
    const [name, ...rest] = fullName
    return <><div>
      <p>{name}</p>
      <h2 className="theater-heading">{rest?.join(" ")}</h2>
    </div>
      <p className="theater-sub">{theater?.address}</p></>
  }
  const handleActive = (e: any) => {
    const showtimeElements = document.querySelector(".movies-showtimes .movies-time-item")
    showtimeElements?.classList?.remove("active")
    e.target?.classList.add("active")
  }

  const getShowtimeByTheater = (movie: any) => {
    const schedule = movie?.schedule?.find((el: any) => moment(filter?.date?.date).format("DD/MM/YYYY") == el?.date)
    const showtimes: any = {}
    const currentTime = moment(new Date(), "HH:mm")
    const isToday = moment(filter?.date?.date).format("DD/MM/YYYY") == moment().format("DD/MM/YYYY")
    for (let i = 0; i < schedule?.times?.length; i++) {
      if (isToday && moment(schedule?.times?.[i]?.time, "HH:mm").isBefore(currentTime)) {
        continue
      } else {
        const idTheater = schedule?.times?.[i].theater_id
        if (showtimes[idTheater]) {
          showtimes[idTheater].push(schedule?.times?.[i])
        } else {
          showtimes[idTheater] = [schedule?.times?.[i]]
        }
      }

    }
    return [...Array.from(Object.entries(showtimes))]?.sort((a: any, b: any) => (b?.[1]?.length - a?.[1]?.length))
  }

  const renderContent = (showtime: any, theaterDetail: TCinima) => {
    const listTimeStandard = showtime?.filter((itm: any) => itm?.room_type_name_en === 'Standard')
    const listTimeDelux = showtime?.filter((itm: any) => itm?.room_type_name_en === "Deluxe")
    const theFirstShowtime = showtime?.[0]?.showtime_id
    const date = moment(filter?.date?.date)?.format("DD/MM/YYYY")
    return (
      <Fragment>
        <div className="movies-rp-body">
          {
            listTimeStandard.length > 0 &&
            <div className="movies-rp-item">
              <p className="movies-rp-title">
                Standard
              </p>
              <div className="movies-time">
                <div className="movies-time-items">
                  {listTimeStandard?.map((showTime: any, index: number) => {
                    return (
                      <div key={index}>
                        <Link onClick={handleActive} href={`/movie/${movie?.id}${theaterDetail ? `?id=${theaterDetail?.area_id}&id_sv=${theaterDetail?.id_server}${showTime?.showtime_id ? '&show_time=' + showTime?.showtime_id : ''}` : ""}${date ? '&date=' + date : ''}`} className={`movies-time-item ${showTime?.showtime_id == theFirstShowtime ? "active" : ""}`}>
                          <span className="inline-block pt-[2px]">{showTime?.time}</span>
                        </Link>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          }
          {
            listTimeDelux?.length > 0 &&
            <div className="movies-rp-item">
              <p className="movies-rp-title">
                Deluxe
              </p>
              <div className="movies-time">
                <div className="movies-time-items">
                  {listTimeDelux?.map((showTime: any, index: number) => {
                    return (
                      <div key={index}>
                        <Link onClick={handleActive} href={`/movie/${movie?.id}${theaterDetail ? `?id=${theaterDetail?.area_id}&id_sv=${theaterDetail?.id_server}${showTime?.showtime_id ? '&show_time=' + showTime?.showtime_id : ''}` : ""}${date ? '&date=' + date : ''}`} className={`movies-time-item ${showTime?.showtime_id == theFirstShowtime ? "active" : ""}`}>
                          {showTime?.time}
                        </Link>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          }
        </div>
      </Fragment>
    )
  }
  const theaterSelected = filter?.idTheater?.id
  const listShowtimes = theaterSelected ? getShowtimeByTheater(movie)?.filter((el: any) => el?.[0] == theaterSelected) : getShowtimeByTheater(movie)

  // if (!showTime || showTime?.length == 0) return null

  return <Fragment>
    {listShowtimes && listShowtimes?.length > 0 ? listShowtimes?.map((el: any) => {
      const theaterDetail = theaters?.find((element: TCinima) => el?.[0] == element.id)
      return <div className="movies-list row" key={el}>
        <div className="col sec-movies">
          {renderTheaterName(theaterDetail!)}
        </div>
        <div className="sec-showtimes col">
          <div className="movies-rp">
            {/* {el?.[1]?.length > 0 ?
                el?.[1]?.map((item: any, index: number) => { */}
            {renderContent(el?.[1], theaterDetail!)}
            {/* })
                : <div className="movies-rp-noti">
                  <img src="/assets/images/movie-updating.png" alt="" />
                  {getValueByKey("Screening_Empty")}
                </div>} */}
          </div>
        </div>
      </div>
    })
      : <div className="movies-rp-noti">
        <img src="/assets/images/movie-updating.png" alt="" />
        {getValueByKey("Screening_Empty")}
      </div>}
  </Fragment>
}