export const renderTextWarningOld = (text: string) => {
  const language = (typeof window !== "undefined") ? localStorage?.getItem('language') : 'vn';
  if(language == "vn"){
    switch(text){
      case "T18": return "Phim dành cho khán giả từ đủ 18 tuổi trở lên (18+)"
      case "T16": return "Phim dành cho khán giả từ đủ 16 tuổi trở lên (16+)"
      case "T13": return "Phim dành cho khán giả từ đủ 13 tuổi trở lên (13+)"
      case "K": return "Phim dành cho khán giả từ dưới 13 tuổi với điều kiện xem cùng cha, mẹ hoặc người giám hộ"
      case "P": return "Phim dành cho khán giả mọi lứa tuổi"
      default: return ""
    }
  }else{
    switch(text){
      case "T18": return "Movies are allowed to be disseminated to viewers aged 18 years and over (18+)"
      case "T16": return "Movies are allowed to be disseminated to viewers aged 16 years and over (16+)"
      case "T13": return "Movies are allowed to be disseminated to viewers aged 16 years and over (13+)"
      case "K": return "Movies are allowed to be disseminated to viewers under the age of 13 provided they are watched with a parent or guardian"
      case "P": return "Movies are allowed to be disseminated to viewers of all ages."
      default: return ""
    }
  }
}